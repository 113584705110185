<template>
	<div class="container">
		<top-header></top-header>
		<div class="monitor width-1300">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/testing' }">产品运行在线监测</el-breadcrumb-item>
				<el-breadcrumb-item>产品磨损在线监测</el-breadcrumb-item>
			</el-breadcrumb>
			<div class="top">
				<div class="select">
					<!-- <el-select v-model="value" placeholder="矿山名称">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-select v-model="value" placeholder="磨机名称">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select> -->
				</div>
				<div class="search">
					<input type="text" placeholder="请输入矿山名称搜索" v-model="inputValue">
					<div class="btn" @click="btnClick">搜索</div>
				</div>
			</div>
			<data-map :userJson="userJson" v-if="isShow" ></data-map>
			<div class="detailed-list">
				<div class="title">
					<h4>特定矿山传感器安装清单</h4>
				</div>
				<div class="list">
					<div class="region">
						亚洲
					</div>
					<div class="database-res" v-for="(item,index) in mineList.data" :key="index"
						@click="toinfo(item.id)">
						<div class="mine_img">
							<img :src="$store.state.imaUrl + item.mining_img" alt="">
						</div>
						<p>矿山名称: {{item.name}}</p>
						<p>公司名称: {{item.company.name}}</p>
						<p>{{item.company.details ? item.company.details : '暂无介绍'}}</p>
						<div class="more">
							<img src="../../../static/images/more.png" >
						</div>
					</div>
				</div>
				<div class="bottom-page">
					<el-pagination layout="prev, pager, next" small :total="mineList.total" :page-size="12"
						:pager-count="5" @current-change="handleCurrentChange" background>
					</el-pagination>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import dataMap from '../../components/map/mapEcharts1.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			dataMap
		},
		data() {
			return {
				// userJson: [{
				// 	amount: 65,
				// 	district: '美国',
				// 	latitude: '37.09024',
				// 	longitude: '-95.712891'
				// }],
				options:[],
				userJson: [],
				mineList: [],
				page: 1,
				isShow: false,
				inputValue:'',
				value:'',
			}
		},
		created() {
			this.getMinelist();
		},
		methods: {
			getMinelist() {
				this.isShow = false;
				this.$get('front/mine/get_list', {
					page: this.page,
					num: 12,
					type:2,
					name:this.inputValue
				}).then(res => {
					this.userJson = [];
					this.mineList = res.data.data;
					for (let i = 0; i < this.mineList.data.length; i++) {
						this.mineList.data[i].location = this.mineList.data[i].location.split(',');
						this.userJson.push({
							companyname: this.mineList.data[i].company.name,
							district: this.mineList.data[i].name,
							latitude: this.mineList.data[i].location[0],
							longitude: this.mineList.data[i].location[1],
							companyimg: this.$store.state.imaUrl + this.mineList.data[i].mining_img
						})
					}
					//console.log(this.mineList.data);
					this.isShow = true;
				})
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getMinelist();
			},
			toinfo(id) {
				// console.log(id);
				this.$router.push({
					query: {
						id:id
					},
					name: 'monitorinfo'
				})
			},
			btnClick(){
				this.getMinelist();
			},
		}
	}
</script>

<style lang="scss">
	#mapEcharts{
		@media screen and(min-width:320px) and(max-width:767px) {
			display: none;
		}
	}
	.monitor {
		margin-top: 62px;
		padding-top: 20px;
		min-height: 805px;

		.top {
			margin: 30px 0;
			display: flex;
			justify-content: space-between;
			@media screen and(min-width:320px) and(max-width:767px) {
				display: block;
			}
			.select{
				.el-select{
					width:200px;
					&:nth-child(1){
						margin-right: 15px;
					}
					@media screen and(min-width:320px) and(max-width:767px) {
						display: inline-block;
						vertical-align: top;
						width: 48%;
						&:nth-child(1){
							margin-right: 3%;
						}
					}
				}
			}

			.search {
				@media screen and(min-width:320px) and(max-width:767px) {
					margin-top: 20px;
				}
				input {
					border: 0;
					outline: none;
					height: 40px;
					padding: 10px;
					box-sizing: border-box;
					color: #999;
					width: 380px;
					vertical-align: middle;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 75%;
						border-top-left-radius: 5px;
						border-bottom-left-radius: 5px;
					}
				}
			
				.btn {
					color: #fff;
					background-color: #00337D;
					height: 40px;
					line-height: 40px;
					padding: 0 20px;
					display: inline-block;
					vertical-align: middle;
					box-sizing: border-box;
					cursor: pointer;
					@media screen and(min-width:320px) and(max-width:767px) {
						width: 25%;
						text-align: center;
						border-top-right-radius: 5px;
						border-bottom-right-radius: 5px;
					}
				}
			}
		}

		.detailed-list {
			margin-top: 30px;

			.list {
				font-size: 14px;
				margin-bottom: 30px;

				.region {
					font-weight: 800;
				}

				.database-res {
					display: inline-block;
					width: 22.7%;
					margin-right: 3%;
					border-radius: 5px;
					// padding: 20px;
					margin-top: 3%;
					box-sizing: border-box;
					font-size: 14px;
					cursor: pointer;
					border: 1px solid #fff;
					transition: all .3s;
					background-color: #fff;
					padding-bottom: 20px;
					position: relative;
					@media screen and(min-width:320px) and (max-width:767px) {
						width: 100%;
						margin-right: 0;
						box-shadow: 0px 7px 21px 0px rgb(0 0 0 / 15%);
						margin-top: 20px;
					}

					&:nth-child(4n+1) {
						margin-right: 0;
					}
					.mine_img{
						height: 200px;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						img{
							height: 100%;
						}
						@media screen and(min-width:320px) and (max-width:767px) {
							img{
								width: 100%;
								height: auto;
							}
						}
					}
				}
				p {
					margin-bottom: 30px;
					padding: 0 30px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-size: 14px;
					&:nth-child(2) {
						text-align: center;
						// font-weight: 800;
						margin-top: 20px;
					}
					&:nth-child(3) {
						text-align: center;
						// font-weight: 800;
						margin-top: 20px;
					}
					&:nth-child(4) {
						text-align: left;
						margin-bottom: 0;
					}
					@media screen and (min-width:320px) and (max-width:767px) {
						text-align: left !important;
						padding: 0 20px;
						&:nth-child(3) {
							width: 75%;
							padding-right: 0;
						}
					}
				}
				.more{
					display: none;
					@media screen and (min-width:320px) and (max-width:767px) {
						display: block;
						width: 40px;
						height: 40px;
						position: absolute;
						bottom: 15px;
						right: 20px;
						img{
							width: 100%;
							height: 100%;
						}
					}
				}
			}

			.bottom-page {
				text-align: center;
				margin-bottom: 30px;
			}
		}
	}
</style>
